import { LinkListType } from "utils/models/CommonTypes";
import {
  BILLING_STATEMENTS,
  CONTACTUS,
  DEVICE_MANAGEMENT_PORTAL,
  ENROLL_IN_BENEFITS,
  ENROLLMENT_HISTORY,
  GROUP_INFORMATION,
  GROUP_REPORTING,
  PASSWORD_HELP,
  PAY_MONTHLY_INVOICE,
  REQUEST_MEMBER_ID_CARD,
  EMPLOYER_DASHBOARD,
} from "../../routes";

export const avMedBaseUrl = "https://www.avmed.com";
export const memberBaseUrl = "https://www.sentarahealthplans.com";
export const memberBrokerContactUs = `${memberBaseUrl}/employers/contact-us`;
export const ContactLink = `/app/${CONTACTUS}`;
export const duoSecurity = "https://duosecurity.wistia.com/medias/653b7tkma3";

export const TopNavLinks = {
  navTopEmployerLink: {
    id: "navTopEmployerLink",
    title: "nav.topMenu.employerLink",
    to: `/app/${EMPLOYER_DASHBOARD}`,
    active: true,
  },
  navTopEmployerHome: {
    id: "navTopEmployerHome",
    title: "nav.topMenu.employerHome",
    to: `https://www.sentarahealthplans.com/employers`,
  },
};

export const ProfileMenuLinks = {
  navLinkProfile: {
    title: "nav.profileMenu.home",
    id: "navLinkProfile",
    to: `http://www.sentarahealthplans.com/employers`,
  },
};

type LeftNavigationsProps = {
  title: string;
  id?: string;
  icon: string;
  list: LinkListType[]
}

export const LeftNavigations: LeftNavigationsProps[] = [
  {
    title: "nav.managementBenefit",
    id: "btnmanagementBenefit",
    icon: "",
    list: [
      {
        title: "nav.menu.GroupInformation",
        id: "lnkGroupInformation",
        to: `/app/${GROUP_INFORMATION}`,
        ariaDescribedBy: "groupInfoContent",
      },
      {
        title: "nav.menu.GroupReporting",
        id: "lnkGroupReporting",
        to: `/app/${GROUP_REPORTING}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
        ariaDescribedBy: "groupReportingContent",
      },
      {
        title: "nav.menu.EnrollInBenefits",
        id: "lnkEmployerBenifitsAndEnrollment",
        to: `/app/${ENROLL_IN_BENEFITS}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
        ariaDescribedBy: "enrollInBenefitsContent",
      },
      {
        title: "nav.menu.EnrollmentHistory",
        id: "lnkEnrollmentHistory",
        to: `/app/${ENROLLMENT_HISTORY}`,
        ariaDescribedBy: "enrollmentHistoryContent",
      },

      {
        title: "nav.menu.RequestMemberIDCard",
        id: "lnkRequestMemberIDCard",
        to: `/app/${REQUEST_MEMBER_ID_CARD}`,       
        ariaDescribedBy: "requestMemberIDCardContent",
      },
    ],
  },
  {
    title: "nav.paymentsBilling",
    icon: "",
    list: [
      {
        title: "nav.menu.PayMonthlyInvoice",
        id: "lnkPayMonthlyInvoice",
        to: `/app/${PAY_MONTHLY_INVOICE}`,
        ariaDescribedBy: "payMonthlyInvoiceContent",
      },
      {
        title: "nav.menu.BillingStatements",
        id: "lnkOrderMemberIdCard",
        to: `/app/${BILLING_STATEMENTS}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
         ariaDescribedBy: "billingStatementsContent",
      },
    ],
  },
  {
    title: "nav.AccountSettings",
    icon: "",
    list: [
      {
        title: "nav.menu.passwordHelp",
        id: "lnkChangePassword",
        to: `${PASSWORD_HELP}`,
        target: "_blank",
      },
      {
        title: "nav.menu.DeviceManagementPortal",
        id: "lnkDeviceManagementPortal",
        to: `/${DEVICE_MANAGEMENT_PORTAL}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
        target: "_blank",
      },
    ],
  },
];

export const FooterNavigation = {
  footerTermsOfUse: {
    dataTestId: "footerTermsOfUse",
    label: "footer.label.termsOfUse",
    url: memberBaseUrl + "/company/policies/terms-of-use-and-disclaimer",
    target: "_blank",
  },
  footerLangAssist: {
    dataTestId: "footerLangAssist",
    label: "footer.label.languagesAssistance",
    url: memberBaseUrl + "/company/policies/language-assistance",
    target: "_blank",
  },
  footerNoticeOfNonDiscrimination: {
    dataTestId: "footerNoticeOfNonDiscrimination",
    label: "footer.label.noticeOfNonDiscrimination",
    url: memberBaseUrl + "/notice-of-nondiscrimination",
    target: "_blank",
  },
  footerContactUs: {
    dataTestId: "footerContactUs",
    label: "footer.label.contactUs",
    url: memberBrokerContactUs,
    target: "_blank",
  },
};
